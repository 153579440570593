
import { computed, defineComponent, reactive } from "vue";
import CustomDatePicker from "@/components/common/DatePicker.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import {
  formattedDatesType,
  pickedDatesType,
} from "@/helpers/types/date-picker.types";

export default defineComponent({
  name: "GenerateReportBlock",
  props: {
    reportName: {
      type: String,
      required: true,
    },
    isGeneratingReport: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    CustomDatePicker,
    BaseButton,
  },

  setup(props, { emit }) {
    const dates: formattedDatesType = reactive({
      from: "",
      to: "",
    });
    const isDatesPicked = computed(() => {
      return !dates.from;
    });

    const setDates = (pickedDates: pickedDatesType) => {
      dates.from = pickedDates.start.toISOString().split("T")[0];
      dates.to = pickedDates.end.toISOString().split("T")[0];
    };

    const generateReport = () => emit("generateReport", dates);

    return {
      dates,
      isDatesPicked,
      setDates,
      generateReport,
    };
  },
});
